<template>
  <div>
    <highcharts :options="mountTusdChart(distributor)" />
  </div>
</template>

<script>
import { i18n } from '../main';
import { Chart } from 'highcharts-vue';

export default {
  components: {
    highcharts: Chart
  },
  props: {
    distributor: Object
  },
  methods: {
    mountTusdChart(distributor) {
      return {
        chart: {
          type: 'column'
        },
        colors: ['#009B3A', '#004F59', '#E57200', '#FFCD00', '#00AD50'],
        title: {
          text: i18n.tc('regulated_market.tariff')
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        series: distributor.tusd,
        credits: {
          enabled: false
        }
      };
    }
  }
};
</script>
