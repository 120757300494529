let profile = {
  profile: {
    title: 'Perfil',
    info_user: 'Informações do usuário',
    name: 'Nome',
    full_name: 'Nome completo',
    active_demand: 'Demanda ativa',
    change_password: 'Alterar senha',
    current_password: 'Senha atual',
    new_password: 'Nova senha',
    repeat_new_password: 'Repetir nova senha',
    change: 'Alterar',
    message_equal_password: 'As senhas devem ser iguais.',
    label_email: 'Email',
    label_login: 'Login',
    password_required: 'Password is required.'
  }
};

export default profile;
