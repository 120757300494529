let glossary = {
  glossary: {
    title: 'Glossário',
    search: 'Pesquisar',
    placeholder: 'Digite sua busca...',
    events: 'Eventos',
    not_result: 'Nenhum resultado encontrado'
  }
};

export default glossary;
