let glossary = {
  glossary: {
    title: 'Glossary',
    search: 'Search',
    placeholder: 'Type your search...',
    events: 'Events'
  }
};

export default glossary;
