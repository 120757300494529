let files = {
  files: {
    title: 'Files',
    reporting: 'Reporting',
    consolidated: 'Consolidated',
    market_news: 'Market News',
    var_market_news: 'Market News',
    contracts: 'Contracts',
    contracts_energy: 'Energy',
    contracts_distributors: 'Distributor',
    breakDown_costs: 'BreakDown of Costs',
    publication_date: 'Publication date',
    file_name: 'File name',
    actions: 'Actions',
    not_found_result: 'We didn`t find results for',
    invoices: 'Invoices',
    studies: 'Studies',
    others: 'Others',
    billingreport: 'Billing Report'
  }
};

export default files;
