import { getAuth, setAuth } from './auth.helpers';
import Config from '@/config/config';
import axios from 'axios';

import Vue from 'vue';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

const CONFIG = new Config();

const state = getAuth() ?? {
  user: null,
  token: null,
  authentication: null,
  acl: null,
  gestal: null,
  tarifaDefault: null //temp
};

export const getters = {
  isAuthenticated: (state) => !!state.token
};

export const mutations = {
  SET_CURRENT_USER(state, { user, token, acl, gestal, logotipo, tarifaDefault }) {
    state.user = user;
    state.token = token;
    state.acl = acl;
    state.gestal = gestal;
    state.logotipo = logotipo;
    state.tarifaDefault = tarifaDefault;

    setAuth({
      user,
      token,
      acl,
      gestal,
      logotipo,
      tarifaDefault
    });
  },
  LOGOUT(state) {
    state.user = null;
    state.token = null;

    setAuth({
      user: null,
      token: null
    });
  }
};

export const actions = {
  CREATE_USER({ commit, dispatch }, _payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(CONFIG.API_URL + '/auth/signup', _payload)
        .then((_response) => {
          resolve(_response);
        })
        .catch((_error) => {
          reject(_error.response.data.code);
        });
    });
  },
  LOGIN({ commit }, { login, senha }) {
    return new Promise((resolve, reject) => {
      let vm = this;
      let payload = {
        login,
        senha
      };

      axios
        .post(CONFIG.API_URL + '/auth/login', payload)
        .then((_response) => {
          const defaultAvatar =
            'https://images.pexels.com/users/avatars/68/tim-savage-333.png?auto=compress&fit=crop&h=256&w=256';

          const {
            userInfo: user,
            permissoes: acl,
            token,
            gestal,
            avatar,
            logotipo,
            tarifaDefault
          } = _response.data;

          user.avatar = avatar ?? defaultAvatar;
          user.nomeCompleto = user.nome;
          user.nome = user.nome.split(' ')[0];
          user.nomeGestorAssociado = _response.data.nomeGestorAssociado;

          delete _response.data.usuario;

          commit('SET_CURRENT_USER', { user, token, acl, gestal, logotipo, tarifaDefault });

          let payload = {
            isAuthenticated: vm.getters['auth/isAuthenticated']
          };
          resolve(payload);
        })
        .catch((_error) => {
          const code =
            typeof _error !== 'undefined' &&
            typeof _error.response !== 'undefined'
              ? _error.response.data.code
              : 401;
          const status =
            typeof _error !== 'undefined' &&
            typeof _error.response !== 'undefined'
              ? _error.response.status
              : 500;

          reject(code, status);
        });
    });
  },
  logOut({ commit }) {
    return new Promise((resolve) => {
      setTimeout(() => {
        document.cookie.split(';').forEach((item) => {
          document.cookie = item
            .replace(/^ +/, '')
            .replace(
              /=.*/,
              '=;expires=' + new Date().toUTCString() + ';path=/'
            );
        });
        document.location.href = '/';
        commit('LOGOUT');
        resolve();
      }, 1000);
    });
  },
  LOGIN_GESTOR({ commit }, { loginUsuario }) {
    return new Promise((resolve, reject) => {
      let vm = this;

      const { token } = getAuth();
      var data = JSON.stringify({
        loginUsuario: loginUsuario
      });

      var config = {
        method: 'post',
        url: `${CONFIG.API_URL}/auth/loginGestor`,
        headers: {
          Bearer: token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then((_response) => {
          const defaultAvatar =
            'https://images.pexels.com/users/avatars/68/tim-savage-333.png?auto=compress&fit=crop&h=256&w=256';

          const {
            userInfo: user,
            permissoes: acl,
            token,
            gestal,
            avatar,
            logotipo,
            tarifaDefault
          } = _response.data;

          user.avatar = avatar ?? defaultAvatar;
          user.nomeCompleto = user.nome;
          user.nome = user.nome.split(' ')[0];
          user.nomeGestorAssociado = _response.data.nomeGestorAssociado;

          delete _response.data.usuario;

          commit('SET_CURRENT_USER', {
            user,
            token,
            acl,
            gestal,
            logotipo,
            tarifaDefault
          });

          let payload = {
            isAuthenticated: vm.getters['auth/isAuthenticated']
          };

          resolve(payload);
        })
        .catch((_error) => {
          const code =
            typeof _error !== 'undefined' &&
            typeof _error.response !== 'undefined'
              ? _error.response.data.code
              : 401;
          const status =
            typeof _error !== 'undefined' &&
            typeof _error.response !== 'undefined'
              ? _error.response.status
              : 500;

          reject(code, status);
        });
    });
  }
};

export default {
  name: 'auth',
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
