const ContratoFornecedorPage = () => import('./ContratoFornecedorPage.vue');
const ContratosDistribuidorasPage = () =>
  import('./ContratosDistribuidorasPage.vue');
const RelatoriosPage = () => import('./RelatoriosPage.vue');
const ConsolidadosPage = () => import('./ConsolidadosPage.vue');
const VarejoFaturamentoPage = () => import('./VarejoFaturamentoPage.vue');
const InfoMercadoPage = () => import('./InfoMercadoPage.vue');
const InfoMercadoVarejoPage = () => import('./InfoMercadoVarejoPage.vue');
const FaturasPage = () => import('./FaturasPage.vue');
const EstudosPage = () => import('./EstudosPage.vue');
const OutrosPage = () => import('./OutrosPage.vue');

export default [
  {
    path: '/files/contratos-energia',
    name: 'contratoFornecedorPage',
    component: ContratoFornecedorPage
  },
  {
    path: '/files/contratos-distribuidoras',
    name: 'contratosDistribuidorasPage',
    component: ContratosDistribuidorasPage
  },
  {
    path: '/files/relatorios',
    name: 'relatoriosPage',
    component: RelatoriosPage
  },
  {
    path: '/files/consolidados',
    name: 'consolidadosPage',
    component: ConsolidadosPage
  },
  {
    path: '/files/varejofaturamento',
    name: 'varejoFaturamentoPage',
    component: VarejoFaturamentoPage
  },
  {
    path: '/files/informativos-mercado',
    name: 'infoMercadoPage',
    component: InfoMercadoPage
  },
  {
    path: '/files/informativos-varejo',
    name: 'infoMercadoVarejoPage',
    component: InfoMercadoVarejoPage
  },
  {
    path: '/files/faturas',
    name: 'faturasPage',
    component: FaturasPage
  },
  {
    path: '/files/estudos',
    name: 'estudosPage',
    component: EstudosPage
  },
  {
    path: '/files/outros',
    name: 'outrosPage',
    component: OutrosPage
  }
];
