let auth = {
  login: {
    invalid: 'E-mail ou senha inválidos',
    invalid_email: 'Por favor, digite um e-mail válido',
    error: 'Não foi possível realizar o login',
    field_obrigatory: 'Campo obrigatório',
    entry: 'Entrar',
    forgot_password: 'Esqueceu sua senha?',
    backLogin: 'Voltar ao login'
  },

  logout: {
    out: 'Saindo...'
  },

  forgot: {
    successMessage: 'Um email foi enviado com uma nova senha de acesso !',
    errorMessage: 'Algo deu errado, ou esse e-mail não existe.'
  }
};

export default auth;
