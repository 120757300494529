<template>
  <div>
    <highcharts :options="mountMediumChart(distributor)" />
  </div>
</template>

<script>
import { i18n } from '../main';
import { Chart } from 'highcharts-vue';

export default {
  components: {
    highcharts: Chart
  },
  props: {
    distributor: Object
  },
  methods: {
    mountMediumChart(distributor) {
      return {
        chart: {
          type: 'line'
        },
        colors: ['#009B3A', '#004F59', '#E57200', '#FFCD00', '#00AD50'],
        title: {
          text: i18n.tc('regulated_market.charges')
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        plotOptions: {
          line: {
            colors: ['#009B3A', '#004F59', '#E57200', '#FFCD00', '#00AD50'],
            dataLabels: {
              enabled: true,
              format: '{point.y:.2f}',
              legend: true
            }
          }
        },
        series: distributor.medium,
        credits: {
          enabled: false
        }
      };
    }
  }
};
</script>
