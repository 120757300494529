<template>
  <b-form @submit="onSubmit" inline class="glossary-search">
    <b-form-input
      class="mr-2 glossary-input"
      v-bind:value="value"
      v-on:input="onInput"
      :placeholder="$t('glossary.placeholder')"
    />

    <b-button type="submit" variant="primary">{{
      $t('glossary.search')
    }}</b-button>
  </b-form>
</template>

<script>
export default {
  name: 'GlossartySearch',
  props: ['value'],
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    onSubmit(event) {
      event.preventDefault();

      this.$emit('search', this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/design/index.scss';

.glossary-search {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 900px;
  justify-content: flex-end;
  margin: 5px 0 15px 0;
}

.glossary-input {
  width: 100% !important;
  max-width: 250px !important;
}
</style>
