<template>
  <main id="tariffMarket">
    <PageHeader :title="$t('regulated_market.title')" :items="breadcrumb" />
    <b-row>
      <b-col>
        <b-card>
          <b-form @submit.prevent="search" inline>
            <b-form-select
              id="inline-form-custom-select-pref"
              class="m-2"
              v-model="t_horosazonal.selected"
              :options="t_horosazonal.options"
              placeholder="Tarifa Horosazonal"
            />

            <b-form-select
              id="inline-form-custom-select-pref"
              class="m-2"
              v-model="c_tensao.selected"
              :options="c_tensao.options"
              placeholder="Classe Tensão"
            />

            <b-button variant="primary" type="submit">{{
              $t('regulated_market.search')
            }}</b-button>
          </b-form>

          <h4 v-if="!this.isValue && !this.loading" class="text-center p-4">
            {{ $t('regulated_market.not_found_message') }}
          </h4>
          <div v-if="this.loading" class="text-center p-2">
            <h4>{{ $t('regulated_market.loading') }}</h4>
            <b-spinner v-if="this.loading" label="Spinning"></b-spinner>
          </div>

          <div v-if="this.isValue && !this.loading">
            <div
              class="distributor-wrapper"
              v-for="distributor in distributors"
              :key="distributor.cnpj"
            >
              <h4 class="text-center p-4">{{ distributor.name }}</h4>

              <b-row>
                <b-col class="col-encargos" md="auto">
                  <ChartEncargos :distributor="distributor"></ChartEncargos>
                </b-col>
                <b-col class="col-tusd">
                  <ChartTarifa :distributor="distributor"></ChartTarifa>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import ChartEncargos from '../../components/chartEncargos';
import t_horosazonal from '../../utils/t.horosazonal';
import c_tensao from '../../utils/c.tensao';
import ChartTarifa from '../../components/chartTarifa';
import PageHeader from '@/components/page-header';
import VueToast from 'vue-toast-notification';
import dayjs from 'dayjs';
import Vue from 'vue';

import { doSearch } from './regulatedMarket.services';
import { getAuth } from '../auth/auth.helpers';
import { i18n } from '../../main';

import 'vue-toast-notification/dist/theme-sugar.css';
import 'dayjs/locale/pt-br';

const { tarifaDefault } = getAuth();

Vue.use(VueToast);

dayjs.locale('pt-br');

export default {
  name: 'FreeMarketPage',
  components: {
    PageHeader,
    ChartEncargos,
    ChartTarifa
  },
  data() {
    return {
      isValue: true,
      loading: false,
      title: i18n.tc('regulated_market.title'),
      distributors: [],
      t_horosazonal: t_horosazonal,
      c_tensao: c_tensao
    };
  },
  methods: {
    search() {
      const horosazonal = this.t_horosazonal.selected;
      const tensao = this.c_tensao.selected;
      this.loading = true;

      doSearch({ horosazonal, tensao })
        .then((distributors) => {
          this.loading = false;

          this.distributors = distributors.map((item) => {
            const itens = item.data.map((itemItens) => itemItens.itens);
            const date = item.data.map((itemDate) => itemDate.date);

            const isContent = itens
              .flat(1)
              .map((itemIsContent) => itemIsContent.tarifaEncargosMedia);

            if (isContent.length <= 0) {
              this.isValue = false;
            }

            if (isContent.length > 0) {
              this.isValue = true;
            }

            const qtdItens = itens.flat(1).length;

            let medium = [];
            let mediumForaPonta = [];

            let tusdPonta = [];
            let tusdFora = [];

            for (let i = 0; i < qtdItens; i++) {
              medium.push(
                [
                  Number(itens.flat(1)[i].tarifaEncargosMedia.toFixed(2)),
                  dayjs(date.flat(1)[i]).format('YYYY')
                ].reverse()
              );

              mediumForaPonta.push(
                [
                  Number(
                    itens.flat(1)[i].tarifaTusdEncargosForaPonta.toFixed(2)
                  ),
                  dayjs(date.flat(1)[i]).format('YYYY')
                ].reverse()
              );

              tusdPonta.push(
                [
                  Number(itens.flat(1)[i].tarifaTusdPonta),
                  dayjs(date.flat(1)[i]).format('YYYY')
                ].reverse()
              );

              tusdFora.push(
                [
                  Number(itens.flat(1)[i].tarifaTusdForaPonta),
                  dayjs(date.flat(1)[i]).format('YYYY')
                ].reverse()
              );
            }

            return {
              name: item.name,
              cnpj: item.cnpj,
              medium: [
                {
                  name: 'TE ' + i18n.tc('regulated_market.average'),
                  data: medium.reverse()
                },
                {
                  name: 'TUSD ' + i18n.tc('regulated_market.off_peak_charges'),
                  data: mediumForaPonta.reverse()
                }
              ],
              tusd: [
                {
                  name: i18n.tc('regulated_market.tip'),
                  data: tusdPonta.reverse()
                },
                {
                  name: i18n.tc('regulated_market.off_the_tip'),
                  data: tusdFora.reverse()
                }
              ]
            };
          });
        })
        .catch((error) => {
          console.log('ERRO: ', error);
          return (this.loading = false);
        });
    }
  },

  computed: {
    breadcrumb: () => [
      {
        text: 'Dashboards',
        href: '/'
      },
      {
        text: i18n.tc('regulated_market.title'),
        active: true
      }
    ]
  },
  created() {
    this.t_horosazonal.selected = tarifaDefault.tarifaHorosazonal;
    this.c_tensao.selected = tarifaDefault.classeTensao;

    this.search();
  }
};
</script>

<style scoped>
.col-tusd,
.col-encargos {
  background-color: #fff;
  width: 48%;
}

@media (max-width: 768px) {
  .col-tusd,
  .col-encargos {
    background-color: #fff;
    width: 100%;
  }
}
</style>
