let profile = {
  profile: {
    title: 'Profile',
    info_user: 'User information',
    name: 'Name',
    full_name: 'Full name',
    active_demand: 'active demand',
    change_password: 'Change Password',
    current_password: 'Current password',
    new_password: 'New Password',
    repeat_new_password: 'Repeat new password',
    change: 'Change',
    message_equal_password: 'Passwords must be the same.'
  }
};

export default profile;
