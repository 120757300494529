import { alreadyLoggedGuard } from './auth.middleware';

const LoginPage = () => import('./LoginPage.vue');

const LogoutPage = () => import('./LogoutPage.vue');

export default [
  {
    path: '/auth/login',
    name: 'login',
    component: LoginPage,
    beforeEnter: alreadyLoggedGuard
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: LogoutPage
  }
];
