<template>
  <nav class="glossary">
    <ul class="glossary-list">
      <li class="glossary-list-item" v-for="item in items" :key="item">
        <a
          @click="onClick($event, item)"
          class="glossary-link"
          :class="{ 'glossary-link-active': item === activeItem }"
        >
          {{ item }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'GlossaryIndex',
  props: {
    activeItem: {
      type: String
    },
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    onClick(event, item) {
      event.preventDefault();
      this.$emit('onChange', item);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/design/index.scss';

.glossary-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  user-select: none;
}

.glossary-list-item {
  margin: 0 12px 12px 0;
}

.glossary-link {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  background: white;
  color: $black;
  font-size: 20px;
  border: 1px solid $gray-350;
  line-height: 1.4;
  border-radius: 4px;
  transition: all 0.2s 0s ease-in-out;
  text-transform: uppercase;
  cursor: pointer;
}

.glossary-link:hover {
  background-color: $blue-200;
  color: $black;
}

.glossary-link:not(.glossary-link-active):hover {
  background-color: $blue-200;
  color: $black;
  border-color: $white;
}

.glossary-link-active {
  background-color: $blue-200;
  color: $black;
  border-color: $blue-500;
}

.glossary-link-active {
  transform: scale(1.1);
}
</style>
