<template>
  <main id="consumptionCCEE">
    <PageHeader :title="$t('consumptionCCEE.title5')" :items="breadcrumb" />

    <b-row>
      <b-col v-if="this.emptyMessage" class="text-center p-2">
        <b-card>
          <h5>MEDIÇÕES 5 MIN NÃO ENCONTRADAS.</h5>
        </b-card>
      </b-col>
      <b-col v-if="this.InitialLoading" class="text-center p-2">
        <b-card>
          <b-spinner v-if="this.InitialLoading" label="Spinning"></b-spinner>
        </b-card>
      </b-col>
      <b-col v-if="!this.InitialLoading && !this.emptyMessage">
        <b-card>
          <b-form @submit.prevent="handleConsumoCCEE(selectedPontoMedicao,mesSelecionado,tipoGrafico)" inline class="d-flex justify-content-center">
            <label for="pm-select">
              {{ $t('realTime.ponto_medicao') }}
            </label>

            <b-form-select id="pm-select" class="m-2" v-model="selectedPontoMedicao" :options="pontosMedicaoCCEE" />

            <label for="mes-select">{{ $t('realTime.month') }}</label>

            <b-form-select id="mes-select" class="m-2" v-model="mesSelecionado" :options="meses" />

            <label for="tipo-select">{{$t('consumptionCCEE.graphicType')}}</label>

            <b-form-select id="tipo-select" class="m-2" v-model="tipoGrafico" :options="tiposGraficos" />

            <b-button variant="primary" type="submit">{{$t('realTime.load')}}</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-col v-if="this.loading === true" class="text-center p-2">
      <b-card>
        <b-spinner v-if="this.loading === true" label="Spinning"></b-spinner>
      </b-card>
    </b-col>

    <b-col style="margin-bottom: -25px" v-if="!loading && isMeasurements" class="text-center p-2">
      <b-card style="height: 3rem; padding: 0rem; background: #004f59" class="text-center">
        <h4 style="margin-top: -10px; color: #fff">
          {{ $t('realTime.consumption') }}
        </h4>
      </b-card>
    </b-col>
    <b-row>
      <b-col v-if="!loading && isMeasurements">
        <b-card>
          <b-table v-if="dadosMedicao.length > 0" striped responsive hover :items="dadosMedicao"
            :fields="fieldsMedicao">
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-col style="margin-bottom: -25px" v-if="!loading && isMeasurements" class="text-center p-2">
      <b-card style="height: 3rem; padding: 0rem; background: #004f59" class="text-center">
        <h4 style="margin-top: -10px; color: #fff">
          {{ "Demanda"}}
        </h4>
      </b-card>
    </b-col>
    <b-row>
      <b-col v-if="!loading && isMeasurements">
        <b-card>
          <b-table v-if="dadosDemanda.length > 0" striped responsive hover :items="dadosDemanda"
            :fields="fieldsDemanda">
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="loading"></b-col>
      <b-col v-if="!loading && isMeasurements">
        <b-card style="max-width: 100%" class="mb-2">
          <b-row>
            <b-col>
              <div class="h2 mb-0 float-right">
                <b-icon icon="exclamation-circle-fill" v-b-modal.modal-center variant="success"></b-icon>
              </div>

              <b-modal id="modal-center" centered v-bind:title="$t('consumptionCCEE.disclaimerSimulatedTitle')"
                title-class="font-18 centered" ok-only ok-variant="success">
                <p style="text-align: justify">
                  {{ $t('consumptionCCEE.disclaimerSimulated') }}
                </p>
              </b-modal>
            </b-col>
          </b-row>

          <ChartConsumoCCEE :relatorioMedicao="relatorioMedicaoCCEE" :nomePontoMedicao="selectedPontoMedicao"
            :mesReferencia="mesSelecionado" :tipoGrafico="tipoGrafico" :datas="datasGrafico"
            :valoresRealizados="valoresRealizados" :valoresSimulados="valoresSimulados"></ChartConsumoCCEE>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>
<script>
import ChartConsumoCCEE from '../../components/chartConsumoCCEE';
import VueCookies from 'vue-cookies';
import PageHeader from '@/components/page-header';
import VueToast from 'vue-toast-notification';
import Config from '@/config/config';
import dayjs from 'dayjs';
import Vue from 'vue';

import { getConsumoCCEE } from './consumoV2.services';
//import { getAuth } from '../auth/auth.helpers';
import { i18n } from '../../main';

import 'vue-toast-notification/dist/theme-sugar.css';
import 'dayjs/locale/pt-br';

//const { gestal } = getAuth();
const CONFIG = new Config();
//const gestalMedicao = gestal.gestalInfo.medicao;

dayjs.locale('pt-br');
Vue.use(VueCookies);
Vue.use(VueToast);

export default {
  name: 'consumptionCCEE',
  components: {
    PageHeader,
    ChartConsumoCCEE
  },
  data() {
    return {
      title: i18n.tc('realTime.title'),
      companySelected: '',
      dataDemandaAtiva: [],
      values: [],
      InitialLoading: false,
      isDemandaAtiva: false,
      isMeasurements: false,
      emptyMessage: false,
      isConsumo: false,
      loading: false,
      currentClient: null,
      url: null,
      responseCompleto: null, //novo (response)
      pontosMedicaoCCEE: [], //novo (combo)
      meses: [], //novo (combo)
      tiposGraficos: ['ACUMULADO', 'NÃO ACUMULADO'],
      mesSelecionado: null, // novo (selecionado)
      selectedPontoMedicao: '', //(selecionado)
      tipoGrafico: '', // (selecionado)
      relatorioMedicaoCCEE: null, //novo (relatório gerado)
      datasGrafico: [], //novo (relatório gerado)
      valoresRealizados: null, //novo (relatório gerado)
      valoresSimulados: null, //novo (relatório gerado)

      config: CONFIG,
      dadosMedicao: [],
      fieldsMedicao: [
        {
          key: 'total',
          label: i18n.tc('consumptionCCEE.total')
        },
        {
          key: 'accomplished',
          label: i18n.tc('consumptionCCEE.accomplished'),
          sortable: false
        },
        {
          key: 'simulated',
          label: i18n.tc('consumptionCCEE.simulated'),
          sortable: false
        },
        {
          key: 'consumoFP',
          label: "Consumo Fora Ponta"
        },
        {
          key: 'consumoP',
          label: "Consumo Ponta"
        }
      ],
      dadosDemanda: [],
      fieldsDemanda: [
        {
          key: 'demandaP',
          label: "Demanda Ponta"
        },
        {
          key: 'horaP',
          label: "Horário Ponta"
        },
        {
          key: 'demandaFP',
          label: "Demanda Fora Ponta"
        },
        {
          key: 'horaFP',
          label: "Horário Fora Ponta"
        }
      ]
    };
  },
  beforeMount: function () {
  },
  created() {
    this.InitialLoading = true;

    getConsumoCCEE()
      .then((response) => {
        if (response.length == 0) {
          this.emptyMessage = true;
          this.InitialLoading = false;
          this.loading = false;
        }

        this.responseCompleto = response;
        //this.meses = this.responseCompleto.meses.map((mes) => mes);

        //CONSTRÓI VETOR COM OS MESES
        this.meses = this.responseCompleto.meses.map((mes) => {
          return {
            value: mes.toString(),
            text: dayjs(mes).format('MMMM/YYYY')
          };
        });

        this.pontosMedicaoCCEE = this.responseCompleto.estudos.map(
          (estudoPontoMedicao) => {
            return {
              value: estudoPontoMedicao.pontoMedicao,
              text:
                estudoPontoMedicao.unidade +
                ': ' +
                estudoPontoMedicao.pontoMedicao
            };
          }
        );

        //DEFINE OS VALORES DE ABERTURA DE TELA
        this.selectedPontoMedicao = this.pontosMedicaoCCEE[0].value;
        this.mesSelecionado = this.meses[0].value;
        this.tipoGrafico = this.tiposGraficos[0];
        this.InitialLoading = false;

        setTimeout(() => {
          this.handleConsumoCCEE(
            this.selectedPontoMedicao,
            this.mesSelecionado,
            this.tipoGrafico
          );
          this.isMeasurements = true;
        }, 200);
      })
      .catch(() => {
        this.InitialLoading = false;
        this.loading = false;
        console.log('ERRO NO RESPONSE.');
        Vue.$toast.error('Erro ao carregar medições CCEE', {
          position: 'top-right',
          duration: 5000
        });
      });
  },
  computed: {
    breadcrumb: () => [
      {
        text: 'Home',
        href: '/'
      },
      {
        text: i18n.tc('realTime.title'),
        active: true
      }
    ],

    fields: () => [
      {
        key: 'nomeMedicao',
        label: i18n.tc('realTime.name')
      },
      {
        key: 'idMedicao',
        label: 'ID',
        sortable: false
      },
      {
        key: 'button',
        label: i18n.tc('realTime.open'),
        sortable: false
      }
    ]
  },
  methods: {
    async handleConsumoCCEE(selectedPM, selectedMonth, tipoGrafico) {
      this.loading = true;

      this.selectedPontoMedicao = selectedPM;
      this.mesSelecionado = selectedMonth;
      this.tipoGrafico = tipoGrafico;

      //FILTRA OS ESTUDOS DE UM PONTO DE MEDIÇÃO
      const estudosPontoList = this.responseCompleto.estudos.filter(
        (itemPontoMedicao) =>
          itemPontoMedicao.pontoMedicao === this.selectedPontoMedicao
      );
      const estudosPontoMedicao = estudosPontoList[0]; //espera-se que seja um único elemento

      //FILTRA O ESTUDO REFERENTE AO MÊS SELECIONADO
      const relatorioMedicaoCCEEList =
        estudosPontoMedicao.relatoriosMensais.filter(
          (itemRelatorio) => itemRelatorio.mesReferencia === this.mesSelecionado
        );
      this.relatorioMedicaoCCEE = relatorioMedicaoCCEEList[0]; //espera-se que seja um único elemento

      //Prenche os vetores com o que de fato irá utilizar
      this.datasGrafico = this.relatorioMedicaoCCEE.datas;

      if (this.tipoGrafico === 'ACUMULADO') {
        this.valoresRealizados =
          this.relatorioMedicaoCCEE.valoresAcumuladosRealizados;
        this.valoresSimulados =
          this.relatorioMedicaoCCEE.valoresAcumuladosSimulados;
      } else {
        this.valoresRealizados =
          this.relatorioMedicaoCCEE.valoresDiariosRealizados;
        this.valoresSimulados =
          this.relatorioMedicaoCCEE.valoresDiariosSimulados;
      }

      setTimeout(() => {
        this.loading = false;
        this.isMeasurements = true;

        this.dadosMedicao = [
          {
            total:
              this.relatorioMedicaoCCEE.medicaoAcumuladaMensal !== null
                ? `${this.relatorioMedicaoCCEE.medicaoAcumuladaMensal.total.toLocaleString(
                  'pt-BR'
                )} MWh`
                : '-',
            accomplished:
              this.relatorioMedicaoCCEE.medicaoAcumuladaMensal !== null
                ? `${this.relatorioMedicaoCCEE.medicaoAcumuladaMensal.realizado.toLocaleString(
                  'pt-BR'
                )} MWh`
                : '-',
            simulated:
              this.relatorioMedicaoCCEE.medicaoAcumuladaMensal !== null
                ? `${this.relatorioMedicaoCCEE.medicaoAcumuladaMensal.simulado.toLocaleString(
                  'pt-BR'
                )} MWh`
                : '-',
            consumoP: this.relatorioMedicaoCCEE.consumoMensal !== null ? 
            `${this.relatorioMedicaoCCEE.consumoMensal.medida.consumoPonta.toLocaleString('pt-BR')} MWh`: '-',
            consumoFP: this.relatorioMedicaoCCEE.consumoMensal !== null ? 
            `${this.relatorioMedicaoCCEE.consumoMensal.medida.consumoFPonta.toLocaleString('pt-BR')} MWh`: '-',
          }
        ];

        this.dadosDemanda = [{
          demandaP: this.relatorioMedicaoCCEE.demandaMaximaMensal !== null ? 
          `${this.relatorioMedicaoCCEE.demandaMaximaMensal.demandaP.toLocaleString('pt-BR')} kW`: '-',
          demandaFP: this.relatorioMedicaoCCEE.demandaMaximaMensal !== null ? 
          `${this.relatorioMedicaoCCEE.demandaMaximaMensal.demandaFP.toLocaleString('pt-BR')} kW`: '-',
          horaP: this.relatorioMedicaoCCEE.demandaMaximaMensal !== null ? 
          `${this.relatorioMedicaoCCEE.demandaMaximaMensal.PHLDT.toLocaleString('pt-BR')} `: '-',
          horaFP: this.relatorioMedicaoCCEE.demandaMaximaMensal !== null ? 
          `${this.relatorioMedicaoCCEE.demandaMaximaMensal.FPHLDT.toLocaleString('pt-BR')} `: '-',
        }]
      });
    }
  }
};
</script>
<style>
@media (max-width: 536px) {
  .btn {
    width: 128px;
    margin: 5px;
  }
}

@media (max-width: 1150px) {

  .col-table,
  .col-chart {
    width: 100%;
  }
}

td {
  font-size: 13px;
}

th {
  font-size: 13px;
}

.col-table {
  width: 30%;
}

.col-chart {
  width: 70%;
}
</style>
