<template>
  <main id="events">
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <Calendar :events="calendarEvents" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import PageHeader from '@/components/page-header.vue';
import Calendar from '@/components/Calendar.vue';

export default {
  name: 'Home',
  components: { PageHeader, Calendar },
  data() {
    return {
      title: 'Eventos',
      calendarEvents: [
        {
          id: 1,
          title: 'Hey!',
          start: new Date().setDate(new Date().getDate() + 2),
          className: 'bg-warning text-white'
        },
        {
          id: 2,
          title: 'See John Deo',
          start: new Date(),
          end: new Date(),
          className: 'bg-success text-white'
        },
        {
          id: 3,
          title: 'Meet John Deo',
          start: new Date().setDate(new Date().getDate() + 2),
          className: 'bg-info text-white'
        },
        {
          id: 4,
          title: 'Buy a Theme',
          start: new Date().setDate(new Date().getDate() + 4),
          className: 'bg-primary text-white'
        }
      ]
    };
  }
};
</script>
