<template>
  <main id="glossary">
    <PageHeader :title="$t('glossary.title')" :items="breadcrumb" />
    <GlossaryIndex
      :items="index"
      :activeItem="activeIndex"
      @onChange="onGlossaryChange"
    />

    <GlossarySearch v-model="search" @search="onSearch" />
    <section v-if="loading" class="loading">
      <b-spinner variant="primary" label="Carregando" class="mt-1" />
    </section>

    <section v-if="items.length">
      <GlossaryItem
        v-for="item of items"
        :key="item.title"
        :title="item.title"
        :description="item.description"
        :link="item.link"
        :linkTitle="item.linkTitle"
        :tags="item.tags"
        :queries="search"
      />
    </section>

    <section v-if="!loading && !items.length" class="no-results">
      <b-alert show variant="warning">{{ $t('glossary.not_result') }}</b-alert>
    </section>
  </main>
</template>

<script>
import PageHeader from '@/components/page-header';
import GlossaryIndex from './components/GlossaryIndex/GlossaryIndex';
import GlossaryItem from './components/GlossaryItem/GlossaryItem';
import GlossarySearch from './components/GlossarySearch/GlossarySearch';
import { letters, glossarySearch } from './glossary.utils';
import { i18n } from '../../main';

export default {
  name: 'GlossaryPage',
  components: {
    GlossaryIndex,
    PageHeader,
    GlossaryItem,
    GlossarySearch
  },
  data() {
    return {
      index: letters,
      activeIndex: '',
      search: '',
      items: [],
      loading: true
    };
  },
  computed: {
    breadcrumb: () => [
      {
        text: 'Dashboards',
        href: '/'
      },
      {
        text: i18n.tc('glossary.title'),
        active: true
      }
    ]
  },
  methods: {
    onGlossaryChange(item) {
      this.activeIndex = item;
      this.search = '';
      this.getItems();
    },
    onSearch(value) {
      this.activeIndex = '';
      this.search = value;
      this.getItems();
    },
    async getItems() {
      this.loading = true;

      const search = this.search ?? '';
      const startsWith = this.activeIndex;

      this.items = await glossarySearch({
        search,
        startsWith
      });

      this.loading = false;
    }
  },
  created() {
    this.getItems();
  }
};
</script>

<style scoped>
.no-results,
.loading {
  max-width: 900px;
}

.loading {
  padding-bottom: 20px;
  text-align: center;
}

.highlightText {
  background-color: yellow;
}
</style>
