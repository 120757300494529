<template>
  <main id="home">
    <PageHeader :title="$t('profile.title')" :items="items" />

    <b-row style="width: 100%; margin: 10px 0">
      <b-col md="auto" class="col-userinfo">
        <h3>{{ $t('profile.info_user') }}</h3>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="avatar" class="label">{{
              $t('profile.label_email')
            }}</label>
            <input
              type="text"
              class="form-control input"
              id="avatar"
              :placeholder="user.email"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              readonly
            />
          </div>

          <div class="col">
            <label for="avatar" class="label">{{
              $t('profile.label_login')
            }}</label>
            <input
              type="text"
              class="form-control input"
              id="avatar"
              :placeholder="user.login"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              readonly
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="avatar" class="label">{{ $t('profile.name') }}</label>
            <input
              type="text"
              class="form-control input"
              id="avatar"
              :placeholder="user.nome"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              readonly
            />
          </div>

          <div class="col">
            <label for="avatar" class="label">{{
              $t('profile.full_name')
            }}</label>
            <input
              type="text"
              class="form-control input"
              id="avatar"
              :placeholder="user.nomeCompleto"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              readonly
            />
          </div>
        </div>
      </b-col>

      <b-col class="col-reset-password">
        <h3 class="title-change-password">
          {{ $t('profile.change_password') }}
        </h3>
        <form @submit="updatePasswords">
          <div class="form-group">
            <label class="label">{{ $t('profile.current_password') }}: </label>
            <div
              style="position: relative"
              :class="{ 'form-group--error': $v.oldPassword.$error }"
            >
              <input
                v-if="showPasswordActually"
                type="text"
                class="form-control"
                v-model.trim="$v.oldPassword.$model"
              />

              <input
                v-if="!showPasswordActually"
                type="password"
                class="form-control"
                v-model.trim="$v.oldPassword.$model"
              />

              <div style="position: absolute; top: 10px; right: 15px">
                <i
                  style="cursor: pointer"
                  @mouseup="showandhidepassword"
                  v-if="showPasswordActually"
                  class="fas fa-eye"
                ></i>
                <i
                  style="cursor: pointer"
                  @mousedown="showandhidepassword"
                  v-if="!showPasswordActually"
                  class="fas fa-eye-slash"
                ></i>
              </div>
            </div>

            <label class="label">{{ $t('profile.new_password') }}: </label>
            <div
              style="position: relative"
              :class="{ 'form-group--error': $v.password.$error }"
            >
              <input
                v-if="showPasswordNew"
                type="text"
                class="form-control"
                v-model.trim="$v.password.$model"
              />
              <input
                v-if="!showPasswordNew"
                type="password"
                class="form-control"
                v-model.trim="$v.password.$model"
              />

              <div style="position: absolute; top: 10px; right: 15px">
                <i
                  style="cursor: pointer"
                  @mouseup="showandhidepasswordNew"
                  v-if="showPasswordNew"
                  class="fas fa-eye"
                ></i>
                <i
                  style="cursor: pointer"
                  @mousedown="showandhidepasswordNew"
                  v-if="!showPasswordNew"
                  class="fas fa-eye-slash"
                ></i>
              </div>
            </div>

            <div class="error" v-if="!$v.password.required">
              {{ $t('profile.password_required') }}
            </div>

            <div class="error" v-if="!$v.password.minLength">
              Password must have at least
              {{ $v.password.$params.minLength.min }} letters.
            </div>

            <label class="label"
              >{{ $t('profile.repeat_new_password') }}:
            </label>
            <div
              style="position: relative"
              :class="{ 'form-group--error': $v.repeatPassword.$error }"
            >
              <input
                v-if="
                  $v.repeatPassword.sameAsPassword && !showPasswordNewRepeat
                "
                type="password"
                class="form-control"
                v-model.trim="$v.repeatPassword.$model"
              />

              <input
                v-if="
                  !$v.repeatPassword.sameAsPassword && showPasswordNewRepeat
                "
                style="border: 1px solid red"
                type="text"
                class="form-control"
                v-model.trim="$v.repeatPassword.$model"
              />

              <input
                v-if="
                  !$v.repeatPassword.sameAsPassword && !showPasswordNewRepeat
                "
                style="border: 1px solid red"
                type="password"
                class="form-control"
                v-model.trim="$v.repeatPassword.$model"
              />

              <input
                v-if="$v.repeatPassword.sameAsPassword && showPasswordNewRepeat"
                type="text"
                class="form-control"
                v-model.trim="$v.repeatPassword.$model"
              />

              <div style="position: absolute; top: 10px; right: 15px">
                <i
                  style="cursor: pointer"
                  @mouseup="showandhidepasswordNewRepeat"
                  v-if="showPasswordNewRepeat"
                  class="fas fa-eye"
                ></i>
                <i
                  style="cursor: pointer"
                  @mousedown="showandhidepasswordNewRepeat"
                  v-if="!showPasswordNewRepeat"
                  class="fas fa-eye-slash"
                ></i>
              </div>
            </div>

            <div
              class="error"
              style="color: red"
              v-if="!$v.repeatPassword.sameAsPassword"
            >
              {{ $t('profile.message_equal_password') }}
            </div>

            <tree-view
              :data="$v"
              :options="{ rootObjectKey: '$v', maxDepth: 2 }"
            ></tree-view>
          </div>

          <button
            v-if="
              !$v.repeatPassword.sameAsPassword ||
              !this.password ||
              !this.repeatPassword ||
              !this.oldPassword
            "
            disabled
            type="submit"
            class="btn btn-primary"
          >
            {{ $t('profile.change') }}
          </button>

          <button
            v-if="
              $v.repeatPassword.sameAsPassword &&
              this.password &&
              this.repeatPassword &&
              this.oldPassword
            "
            type="submit"
            class="btn btn-primary"
          >
            {{ $t('profile.change') }}
          </button>
        </form>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import { getAuth } from '../auth/auth.helpers';
import { i18n } from '../../main';

import PageHeader from '@/components/page-header.vue';
import Vuelidate from 'vuelidate';
import VueToast from 'vue-toast-notification';
import Config from '@/config/config';
import Vue from 'vue';
import store from '../../store';
import router from '../../router';

import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);
Vue.use(Vuelidate);

const { mapState } = createNamespacedHelpers('auth');
const { user, token } = getAuth();
const CONFIG = new Config();

export default {
  name: 'Home',
  components: { PageHeader },

  data() {
    return {
      title: i18n.tc('profile.title'),
      showPasswordActually: false,
      showPasswordNew: false,
      showPasswordNewRepeat: false,

      oldPassword: '',
      password: '',
      repeatPassword: '',

      userLogin: user.login
    };
  },
  validations: {
    oldPassword: {
      required,
      minLength: minLength(1)
    },
    password: {
      required: false,
      minLength: minLength(1)
    },
    repeatPassword: {
      required,
      sameAsPassword: sameAs('password')
    }
  },
  methods: {
    showandhidepassword(event) {
      event.preventDefault();
      this.showPasswordActually = !this.showPasswordActually;
    },

    showandhidepasswordNew(event) {
      event.preventDefault();
      this.showPasswordNew = !this.showPasswordNew;
    },

    showandhidepasswordNewRepeat(event) {
      event.preventDefault();
      this.showPasswordNewRepeat = !this.showPasswordNewRepeat;
    },

    updatePasswords(e) {
      e.preventDefault();
      var axios = require('axios');
      var data = JSON.stringify({
        userLogin: this.userLogin,
        oldPassword: this.oldPassword,
        newPassword: this.repeatPassword
      });

      var config = {
        method: 'post',
        url: `${CONFIG.API_URL}/usuario/portal/updatePassword`,
        headers: {
          Bearer: token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          Vue.$toast.success('Senha alterada com sucesso !', {
            position: 'top-right',
            duration: 5000
          });
          store.dispatch('auth/logOut');

          setTimeout(() => {
            return router.push({ path: '/auth/logout' });
          }, 1000);
        })
        .catch(function () {
          Vue.$toast.error('Erro ao alterar senha, tente novamente.', {
            position: 'top-right',
            duration: 5000
          });
        });
    }
  },
  computed: {
    items: () => [
      {
        text: 'Dashboards',
        href: '/'
      },
      {
        text: i18n.tc('profile.title'),
        active: true
      }
    ],
    ...mapState(['user'])
  }
};
</script>

<style scoped>
.label {
  margin-left: 10px;
  margin-top: 10px;
}

.input {
  cursor: default;
}

.col-userinfo,
.col-reset-password {
  background-color: #fff;
  padding: 25px;
  margin: 5px;
  border-radius: 10px;
  width: 48%;
}

@media (max-width: 768px) {
  .col-userinfo,
  .col-reset-password {
    background-color: #fff;
    padding: 25px;
    border-radius: 10px;
    width: 100%;
  }
}

.title-change-password {
  margin-bottom: 20px;
}

.iconeye {
  position: absolute;
  right: 20px;
  top: 12px;
}

.b-row-input-password {
  position: relative;
}
</style>
