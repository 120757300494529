<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>

<template>
  <b-row>
    <div class="col-12">
      <div cols="12" sm="6">
        <h2 cols="12" sm="6">{{ title }}</h2>

        <div ccols="12" sm="6">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </b-row>
</template>
