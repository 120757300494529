<template>
  <FullCalendar ref="fullCalendar" :options="calendarOptions" />
</template>

<script>
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';

export default {
  components: {
    FullCalendar
  },
  props: ['events'],
  data() {
    return {
      calendarOptions: {
        locales: [ptBrLocale],
        locale: 'pt-br',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: ''
        },
        plugins: [dayGridPlugin, bootstrapPlugin],
        initialView: 'dayGridMonth',
        themeSystem: 'bootstrap',
        initialEvents: this.events
      }
    };
  }
};
</script>
