<script>
import MetisMenu from 'metismenujs/dist/metismenujs';

import { getAuth } from '../features/auth/auth.helpers';
import { i18n } from '../main';
const { acl } = getAuth();

export default {
  data() {
    return {
      arrow: false,
      acl: acl
    };
  },

  mounted: function () {
    new MetisMenu('#side-menu');
    const links = document.getElementsByClassName('side-nav-link-ref');
    let matchingMenuItem = null;
    const paths = [];

    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]['pathname']);
    }
    const itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/');
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active');
      const parent = matchingMenuItem.parentElement;

      if (parent) {
        parent.classList.add('mm-active');
        const parent2 = parent.parentElement.closest('ul');
        if (parent2 && parent2.id !== 'side-menu') {
          parent2.classList.add('mm-show');

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('mm-active');
            const childAnchor = parent3.querySelector('.has-arrow');
            const childDropdown = parent3.querySelector('.has-dropdown');
            if (childAnchor) childAnchor.classList.add('mm-active');
            if (childDropdown) childDropdown.classList.add('mm-active');

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== 'side-menu') {
              parent4.classList.add('mm-show');
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== 'side-menu') {
                parent5.classList.add('mm-active');
                const childanchor = parent5.querySelector('.is-parent');
                if (childanchor && parent5.id !== 'side-menu') {
                  childanchor.classList.add('mm-active');
                }
              }
            }
          }
        }
      }
    }
  },

  computed: {
    menuitem: () => {
      return [
        {
          id: 1,
          label: 'Menu',
          isTitle: true
        },
        {
          id: 2,
          label: 'Home',
          name: 'Home',
          icon: 'bx-home-circle',
          link: '/'
        },
        {
          id: 3,
          label: 'Medições',
          name: 'medicoes_menu',
          icon: 'bxs-flag-alt',
          subItems: [
            {
              id: 4,
              label: 'Telemetria', //i18n.tc('realTime.title') //Anterior
              name: 'medicao_tempo_real_menu',
              link: '/real-time/realtime',
              parentId: 3
            },
            {
              id: 5,
              label: i18n.tc('consumptionCCEE.title'),
              name: 'medicao_ccee_view',
              link: '/consumption-ccee/consumptionCCEE',
              parentId: 3
            },
            {
              id: 50,
              label: i18n.tc('consumptionCCEE.title'),
              name: 'medicao_5min_view', // alterar permissao para clientes e gestores
              link: '/medicoes/consumoV2',
              parentId: 3
            }
          ]
        },
        {
          id: 6,
          label: i18n.tc('regulated_market.title'),
          name: 'mr_menu',
          icon: 'bxs-flag-alt',
          subItems: [
            {
              id: 8,
              label: i18n.tc('regulated_market.tariff_menu'),
              name: 'mr_tarifas_view',
              link: '/regulated-market/groupedTariff',
              parentId: 6
            }
          ]
        },
        {
          id: 9,
          label: i18n.tc('free_market.title'),
          name: 'ml_menu',
          icon: 'bx-line-chart',
          subItems: [
            {
              id: 10,
              label: i18n.tc('free_market.prices'),
              name: 'ml_precos_view',
              link: '/free-market/prices',
              parentId: 9
            },
            {
              id: 11,
              label: i18n.tc('free_market.study'),
              link: '/',
              parentId: 9
            }
          ]
        },
        {
          id: 12,
          label: i18n.tc('analize_study.title'),
          icon: 'bxs-bar-chart-alt-2',
          subItems: [
            {
              id: 13,
              label: i18n.tc('analize_study.historical_economics'),
              link: '/',
              parentId: 12
            },
            {
              id: 14,
              label: i18n.tc('analize_study.projected_economy'),
              link: '/',
              parentId: 12
            },
            {
              id: 15,
              label: i18n.tc('analize_study.budget'),
              link: '/',
              parentId: 12
            },
            {
              id: 16,
              label: i18n.tc('analize_study.breakDown_costs'),
              link: '/',
              parentId: 12
            }
          ]
        },
        {
          id: 17,
          label: i18n.tc('files.title'),
          name: 'arquivos_menu',
          icon: 'bx-file',
          badge: {
            variant: 'success',
            text: 'novo'
          },
          subItems: [
            {
              id: 18,
              label: i18n.tc('files.reporting'),
              name: 'arquivos_relatorios_view',
              link: '/files/relatorios',
              parentId: 17
            },
            {
              id: 30,
              label: i18n.tc('files.consolidated'),
              name: 'arquivos_relatorios_view',
              link: '/files/consolidados',
              parentId: 17
            },
            {
              id: 31,
              label: i18n.tc('files.billingreport'),
              name: 'arquivos_varejo_faturamento_view',
              link: '/files/varejofaturamento',
              parentId: 17
            },
            {
              id: 19,
              label: i18n.tc('files.market_news'),
              name: 'arquivos_informativos_view',
              link: '/files/informativos-mercado',
              parentId: 17
            },
            {
              id: 32,
              label: i18n.tc('files.market_news'),
              name: 'arquivos_informativos_varejo_view',
              link: '/files/informativos-varejo',
              parentId: 17
            },
            {
              id: 20,
              label: i18n.tc('files.contracts'),
              name: 'arquivos_contratos_view',
              parentId: 17,
              subItems: [
                {
                  id: 26,
                  label: i18n.tc('files.contracts_energy'),
                  name: 'arquivos_contratos_fornecedor_view',
                  link: '/files/contratos-energia',
                  parentId: 20
                },
                {
                  id: 27,
                  label: i18n.tc('files.contracts_distributors'),
                  name: 'arquivos_contratos_distribuidoras_view',
                  link: '/files/contratos-distribuidoras',
                  parentId: 20
                }
              ]
            },
            {
              id: 21,
              label: i18n.tc('files.invoices'),
              name: 'arquivos_faturas_view',
              link: '/files/faturas',
              parentId: 17
            },
            {
              id: 28,
              label: i18n.tc('files.studies'),
              name: 'arquivos_estudos_view',
              link: '/files/estudos',
              parentId: 17
            },
            {
              id: 29,
              label: i18n.tc('files.others'),
              name: 'arquivos_outros_view',
              link: '/files/outros',
              parentId: 17
            }
          ]
        },
        {
          id: 22,
          label: i18n.tc('glossary.events'),
          icon: 'bx-calendar',
          link: '/eventos'
        },
        {
          id: 23,
          label: i18n.tc('glossary.title'),
          name: 'glossario_menu',
          icon: 'bx-book',
          link: '/glossary'
        },
        {
          id: 24,
          label: i18n.tc('management.title'),
          name: 'management_menu',
          icon: 'bxs-face',
          subItems: [
            {
              id: 25,
              label: i18n.tc('management.associate_user.title'),
              name: 'list_usuario',
              link: '/management/associate-user',
              parentId: 24
            }
          ]
        }
      ];
    }
  }, // last id: 31 arquivos_varejo_faturamento_view

  methods: {
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    hasPermission(item, permissions) {
      return (
        item.name !== undefined &&
        permissions !== undefined &&
        permissions.includes(item.name)
      );
    }
  }
};
</script>

<template>
  <div id="sidebar-menu">
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-for="item in menuitem">
        <li class="menu-title" v-if="item.isTitle" :key="item.id">
          {{ item.label }}
        </li>
        <li
          v-for="itemAcl in acl"
          v-if="!item.isTitle && !item.isLayout && itemAcl === item.name"
          :key="item.id"
        >
          <a
            v-if="hasItems(item)"
            href="javascript:void(0);"
            class="is-parent"
            :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
          >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ item.label }}</span>
            <span
              :class="`badge badge-pill badge-${item.badge.variant} float-right`"
              v-if="item.badge"
              >{{ item.badge.text }}</span
            >
          </a>

          <router-link
            :to="item.link"
            v-if="!hasItems(item)"
            class="side-nav-link-ref"
          >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ item.label }}</span>
            <span
              :class="`badge badge-pill badge-${item.badge.variant} float-right`"
              v-if="item.badge"
              >{{ item.badge.text }}</span
            >
          </router-link>

          <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li v-for="(subitem, index) of item.subItems" :key="index">
              <router-link
                :to="subitem.link"
                v-if="!hasItems(subitem) && hasPermission(subitem, acl)"
                class="side-nav-link-ref"
              >
                {{ subitem.label }}</router-link
              >
              <a
                v-if="hasItems(subitem)"
                class="side-nav-link-a-ref has-arrow"
                href="javascript:void(0);"
              >
                {{ subitem.label }}</a
              >
              <ul
                v-if="hasItems(subitem)"
                class="sub-menu mm-collapse"
                aria-expanded="false"
              >
                <li
                  v-for="(subSubitem, index) of subitem.subItems"
                  :key="index"
                >
                  <router-link
                    :to="subSubitem.link"
                    v-if="
                      !hasItems(subSubitem) && hasPermission(subSubitem, acl)
                    "
                    class="side-nav-link-ref"
                    >{{ subSubitem.label }}</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </template>
    </ul>
  </div>
</template>
