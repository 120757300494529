<template>
  <main id="freeMarket">
    <PageHeader :title="$t('free_market.title')" :items="breadcrumb" />

    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <div class="h2 mb-0 float-right">
                <b-icon
                  icon="exclamation-circle-fill"
                  v-b-modal.modal-center
                  variant="success"
                ></b-icon>
              </div>

              <b-modal
                id="modal-center"
                centered
                v-bind:title="$t('free_market.disclaimerPriceTitle')"
                title-class="font-18 centered"
                ok-only
                ok-variant="success"
              >
                <p style="text-align: justify">
                  {{ $t('free_market.disclaimerPrice') }}
                </p>
              </b-modal>
            </b-col>
          </b-row>

          <div v-if="this.loadingFWD === true" class="text-center p-2">
            <b-spinner
              v-if="this.loadingFWD === true"
              label="Spinning"
            ></b-spinner>
          </div>

          <ChartFreeMarket
            :series="this.fwdChartSeries"
            :key="componentKey"
          ></ChartFreeMarket>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <b-form
            @submit.prevent="search"
            inline
            class="d-flex justify-content-center"
          >
            <label for="inline-form-custom-select-pref">{{
              $t('free_market.submarket')
            }}</label>

            <b-form-select
              id="inline-form-custom-select-pref"
              class="m-2"
              v-model="submercados.selected"
              :options="submercados.values"
            />

            <b-button variant="primary" type="submit">{{
              $t('free_market.apply')
            }}</b-button>
          </b-form>

          <div v-if="this.loading === true" class="text-center p-2">
            <b-spinner
              v-if="this.loading === true"
              label="Spinning"
            ></b-spinner>
          </div>

          <b-row v-if="this.loading === false">
            <b-col>
              <ChartPLD
                :series="this.pldChartSeries"
                :categories="this.pldChartCategories"
                :title="this.pldChartTitle"
              />
              <h2 class="projection">
                {{ $t('free_market.projection') }}
              </h2>
            </b-col>

            <b-col>
              <b-container>
                <b-row>
                  <b-col>
                    <ChartFwdAhead
                      :datas="this.fwdAheadValues[0].anoRef"
                      :title="`Preços Futuros: FWD ${this.submercados.selected}`"
                      :valoresCO="this.fwdAheadValues[0].valor"
                      :nomeCurvaCO="`FWD ${this.submercados.selected} CONV`"
                      :valoresI5="this.fwdAheadValues[1].valor"
                      :nomeCurvaI5="`FWD ${this.submercados.selected} I5`"
                    />
                  </b-col>
                </b-row>
                <b-row style="margin-top: 25px">
                  <b-col>
                    <h5 class="text-center mt-1 mb-3">{{ titleAgio }}</h5>

                    <b-table
                      v-if="agioBoard.values.length > 0"
                      striped
                      responsive
                      hover
                      :items="agioBoard.values"
                      :fields="agioFieds"
                      style="font-size: 90%"
                    >
                    </b-table>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import ChartFreeMarket from '../../components/chartFreeMarket';
import submercados from '../../utils/submercados';
import PageHeader from '@/components/page-header';
import ChartPLD from '../../components/chartPLD';
import ChartFwdAhead from '../../components/chartFwdAhead.vue';
import dayjs from 'dayjs';

import { i18n } from '../../main';
import {
  getAgioBoard,
  getFwdChartSeries,
  getPdlChartSeries,
  getFWDYearAhead
} from './freeMarket.services';

import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

export default {
  name: 'FreeMarketPage',
  components: {
    PageHeader,
    ChartFreeMarket,
    ChartPLD,
    ChartFwdAhead
  },
  data() {
    return {
      loading: false,
      titlePLD: i18n.tc('free_market.title_pld'),
      titleAgil: i18n.tc('free_market.title_agil'),
      title: i18n.tc('free_market.title_price'),
      fwdChartSeries: [],
      pldChartSeries: [],
      pldChartCategories: [],
      pldChartTitle: '',

      submercados: submercados,
      agioBoard: {},
      fwdAheadValues: [],
      agioFieds: [
        { key: 'key', label: 'Agio' },
        {
          key: 'value',
          label: 'Valor'
        }
      ],
      titleAgio: '',
      monthAgio: ''
    };
  },
  methods: {
    search() {
      const value = this.submercados.selected;

      this.getPdlChartSeries(value);
      this.getFWDYearAhead(value);
      this.getAgioBoard(value);
    },

    getPdlChartSeries(submercado) {
      this.loading = true;
      getPdlChartSeries(submercado).then(({ series, projections }) => {
        const categories = [...series, ...projections].reduce((acc, serie) => {
          const year = dayjs(serie[0]).format('YYYY');
          const month = dayjs(serie[0]).format('MMM');

          const lastItem = acc[acc.length - 1];
          if (lastItem?.name !== year) {
            acc.push({
              name: year,
              categories: []
            });
          }

          const updatedLastItem = acc[acc.length - 1];
          updatedLastItem.categories.push(month);

          return acc;
        }, []);

        const data = series.map((serie) => serie[1]);

        for (let i = 0; i < projections.length; i++) {
          const x = data.length;

          data.push({
            x,
            y: projections[i][1],
            color: '#fff',
            borderColor: '#004f5a',
            borderWidth: 2,
            dashStyle: 'ShortDot',
            dataLabels: {
              color: '#000'
            }
          });
        }

        this.loading = false;
        this.pldChartTitle =
          'PLD ' +
          i18n.tc('free_market.monthly') +
          ' ' +
          this.submercados.selected;
        this.titleAgil =
          i18n.tc('free_market.agile') +
          ' ' +
          `${this.submercados.selected} - ` +
          i18n.tc('free_market.current_month');
        this.pldChartCategories = categories;
        this.pldChartSeries = [
          {
            data
          }
        ];
      });
    },

    getAgioBoard(submercado) {
      getAgioBoard(submercado).then((data) => {
        this.agioBoard = data;

        this.titleAgio =
          i18n.tc('free_market.agile') +
          ' ' +
          this.submercados.selected +
          ' (' +
          this.agioBoard.month +
          ')';
      });
    },

    getFWDYearAhead(submercado) {
      getFWDYearAhead(submercado).then((data) => {
        this.fwdAheadValues = data;
      });
    }
  },
  created() {
    this.getAgioBoard('SE');
    this.getPdlChartSeries('SE');

    this.getFWDYearAhead('SE');

    this.loadingFWD = true;
    getFwdChartSeries().then((series) => {
      this.fwdChartSeries = series;
      this.loadingFWD = false;
      this.componentKey += 1;
    });
  },
  computed: {
    breadcrumb: () => [
      {
        text: 'Dashboards',
        href: '/'
      },
      {
        text: i18n.tc('free_market.title'),
        active: true
      }
    ]
  }
};
</script>

<style scoped>
.projection {
  color: #004f5a;
  border: 2px dotted #004f5a;
  padding: 5px 20px;
  margin-left: 62px;
  display: inline-block;
  font-size: 14px;
}

.agio-table tr:nth-child(even) {
  background-color: #fff2cc;
}

.agio-table td,
.agio-table th {
  padding: 10px;
}
</style>
