let free_market = {
  free_market: {
    title: 'Mercado livre',
    titleChart: 'Forward Sudeste Convencional',
    title_pld: 'SE - Convencional',
    title_agil: 'Ágio SE - Convencional - Mês vigente',
    title_price: 'Mercado Livre - Preços',
    apply: 'Aplicar',
    prices: 'Preços',
    study: 'Estudos',
    submarket: 'Submercado',
    current_month: 'Mês Vigente',
    all: 'Todos',
    value: 'Valor (R$)',
    value_pld: 'Valor (R$/MWh)',
    values: 'Valores',
    monthly: 'Mensal',
    agile: 'Agio',
    conventional: 'Convencional',
    projection: 'Projeção',
    disclaimerPriceTitle: 'Curvas de Preços Futuros',
    disclaimerPrice:
      'As curvas de preços futuros são provenientes da marcação de mercado feita diariamente pela Libra Energia. Estes preços não representam necessariamente negócios realizados e podem estar baseados nas expectativas de mercado dos agentes ou em valores indicativos de compra e venda (bid/offer). Sob nenhuma hipótese os preços apresentados devem ser interpretados como ofertas de compra ou venda de energia entre a Libra Energia e seus clientes.'
  }
};

export default free_market;
